import request from '@/utils/request1';

// 日管控查询
export function dailyControlList(data) {
    return request({ url: '/daily_control/QDailyControlApi22', method: 'post', data })
}

// 日管控新增
export function dailyControlAdd(data) {
    return request({ url: '/daily_control/ADailyControlApi22', method: 'post', data })
}

// 日管控编辑
export function dailyControlEdit(data) {
    return request({ url: '/daily_control/UDailyControlApi22', method: 'post', data })
}

// 日管控删除
export function dailyControlDel(data) {
    return request({ url: '/daily_control/DDailyControlApi22', method: 'post', data })
}

// 周排查查询
export function weekControlList(data) {
    return request({ url: '/week_control/QWeekControlApi', method: 'post', data })
}

// 周排查新增
export function weekControlAdd(data) {
    return request({ url: '/week_control/AWeekControlApi', method: 'post', data })
}

// 周排查编辑
export function weekControlEdit(data) {
    return request({ url: '/week_control/UWeekControlApi', method: 'post', data })
}

// 周排查删除
export function weekControlDel(data) {
    return request({ url: '/week_control/DWeekControlApi', method: 'post', data })
}

// 月调度查询
export function monthControlList(data) {
    return request({ url: '/month_control/QMonthControlApi', method: 'post', data })
}

// 月调度新增
export function monthControlAdd(data) {
    return request({ url: '/month_control/AMonthControlApi', method: 'post', data })
}

// 月调度编辑
export function monthControlEdit(data) {
    return request({ url: '/month_control/UMonthControlApi', method: 'post', data })
}

// 月调度删除
export function monthControlDel(data) {
    return request({ url: '/month_control/DMonthControlApi', method: 'post', data })
}



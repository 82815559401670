<template>
  <div>
    <a-card title="周排查">
      <a-row :gutter="16">
        <a-col :span="24" style="max-width: 200px; margin-bottom: 12px">
          <a-input v-model="searchForm.search" placeholder="请输入关键词" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>

        <div style="margin-bottom: 12px; float: right">
          <a-button type="primary" icon="plus" style="margin: 0 8px" @click="openFormModal(form)">新增</a-button>
        </div>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
                size="small"
                :pagination="pagination"
                :columns="columns"
                :dataSource="items"
                rowKey="id"
                :loading="loading"
                @change="tableChange"
        >
          <div slot="is_active" slot-scope="value">
            <a-tag :color="value ? 'green' : 'red'">{{ value ? "激活" : "冻结" }}</a-tag>
          </div>
          <div slot="action" slot-scope="value, item">
            <a-button-group>
              <a-button icon="edit" size="small" @click="openFormModal(item)">编辑</a-button>
              <a-popconfirm title="确定删除吗" @confirm="destroy(item.id)">
                <a-button type="danger" icon="delete" size="small">删除</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>
    <form-modal
            v-model="visible"
            :form="targetItem"
            :clientsClassificationOptions="clientsClassificationItems"
            @create="create"
            @update="update"
    />
    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />正在导入中, 请等待...</div>
    </a-modal>
  </div>
</template>

<script>
  import { weekControlList, weekControlDel } from "@/api/control";

  export default {
    name: "Warehouse",
    components: {
      FormModal: () => import("./FormModal.vue"),
    },
    data() {
      return {
        columns: [
          {
            title: "检查日期",
            dataIndex: "others.check_date"
          },
          {
            title: "管控日期范围",
            dataIndex: "others.control_date"
          },
          {
            title: "记录编号",
            dataIndex: "others.record_code"
          },
          {
            title: "上周回顾",
            dataIndex: "others.review"
          },
          {
            title: "本周排查",
            dataIndex: "others.troubleshooting"
          },
          {
            title: "整改措施",
            dataIndex: "others.measures"
          },
          {
            title: "备注",
            dataIndex: "others.remark"
          },
          {
            title: "参加人员",
            dataIndex: "others.participants"
          },
          {
            title: "操作",
            dataIndex: "action",
            scopedSlots: { customRender: "action" },
            width: "156px",
          },
        ],
        pagination: { current: 1, total: 0, pageSize: 10 },
        searchForm: { search: "", page: 1, page_size: 10 },
        loading: false,
        items: [],
        clientsClassificationItems: [],
        visible: false,
        targetItem: { others: {} },
        form: { others: {} },
        importLoading: false,
      };
    },
    methods: {
      initialize() {
        this.list();
      },
      list() {
        this.loading = true;
        weekControlList(this.searchForm)
                .then((data) => {
                  this.pagination.total = data.count;
                  this.items = data.data;
                })
                .finally(() => {
                  this.loading = false;
                });
      },
      create() {
        this.list();
      },
      update() {
        this.list();
      },
      search() {
        this.pagination.current = 1;
        this.searchForm.page = 1;
        this.list();
      },
      openFormModal(item) {
        this.targetItem = { ...item }
        this.visible = true;
      },
      destroy(id) {
        weekControlDel({ id: id })
                .then((data) => {
                  this.$message.success("删除成功");
                  this.list();
                })
                .finally(() => {
                  this.loading = false;
                });
      },
      tableChange(pagination, filters, sorter) {
        this.searchForm.page = pagination.current;
        this.pagination.current = pagination.current;
        this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
        this.list();
      },
    },
    mounted() {
      this.initialize();
    },
  };
</script>
